import { template as template_54f909e197e14903b773da58794a9c07 } from "@ember/template-compiler";
const FKText = template_54f909e197e14903b773da58794a9c07(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
