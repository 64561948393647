import { template as template_c51a1a03f39d46518a5edafe3c1f44f5 } from "@ember/template-compiler";
const FKLabel = template_c51a1a03f39d46518a5edafe3c1f44f5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
