import { template as template_40764ded3ccf4ab9ad9c41f6dc33d69d } from "@ember/template-compiler";
const SidebarSectionMessage = template_40764ded3ccf4ab9ad9c41f6dc33d69d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
