import { template as template_eac6029fb46743ba9283d1e905443734 } from "@ember/template-compiler";
const FKControlMenuContainer = template_eac6029fb46743ba9283d1e905443734(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
